"use client";

import React, { useEffect, useState } from "react";

export default function Section({
  id,
  children,
  topMd,
  topLg,
  topXl,
  bottomMd,
  bottomLg,
  bottomXl,
  ...props
}) {
  const [paddingTop, setPaddingTop] = useState(`${topMd || 0}px`);
  const [paddingBottom, setPaddingBottom] = useState(`${bottomMd || 0}px`);

  useEffect(() => {
    const updatePadding = () => {
      const width = window.innerWidth;

      if (width >= 1200) {
        // Extra large screens (>= 1200px)
        setPaddingTop(`${topXl || topLg || topMd || 0}px`);
        setPaddingBottom(`${bottomXl || bottomLg || bottomMd || 0}px`);
      } else if (width >= 991) {
        // Large screens (991px - 1199px)
        setPaddingTop(`${topLg || topMd || 0}px`);
        setPaddingBottom(`${bottomLg || bottomMd || 0}px`);
      } else {
        // Medium screens (below 991px)
        setPaddingTop(`${topMd || 0}px`);
        setPaddingBottom(`${bottomMd || 0}px`);
      }
    };

    // Initial padding setup
    updatePadding();

    // Add resize event listener
    window.addEventListener("resize", updatePadding);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updatePadding);
    };
  }, [topXl, topLg, topMd, bottomXl, bottomLg, bottomMd]);

  return (
    <section
      id={id}
      {...props}
      style={{
        paddingTop,
        paddingBottom,
        ...props.style,
      }}>
      {children}
    </section>
  );
}
