import { Icon } from "@iconify/react";
import React from "react";

export default function Rating({ ratingNumber }) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        marginBottom: "1rem",
        justifyContent: "center",
        color: "gold",
      }}>
      {Array.from({ length: ratingNumber }).map((_, i) => (
        <i key={i}>
          <Icon icon="fa6-solid:star" />
        </i>
      ))}
    </div>
  );
}
