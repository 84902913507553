import Link from "next/link";
import { useLocale, useTranslations } from "next-intl";
export default function DownloadButton() {
  const t = useTranslations("home");
  const AL = useTranslations("ariaLabels");
  const currentLang = useLocale();
  return (
    <Link
      href={`/${currentLang}/opt-in`}
      target="_blank"
      aria-label={AL("links.opt-in")}
      >
      <button
        className="btn-download mb-0"
        onClick={() => {
          if (typeof window.ml === "function") {
            window.ml("show", "caKSCd", true);
          } else {
            console.error("ml function is not defined");
          }
        }}>
        {t("banner.btnText")}
      </button>
    </Link>
  );
}
