'use client';
import React, { memo } from 'react';
import SectionHeading from '../../SectionHeading';
import TestimonialCarouselStyle2 from '../../Slider/TestimonialCarouselStyle2';

const TestimonialSectionStyle3 = ({
  sectionTitle,
  sectionTitleUp,
  data,
}) => {
  return (
    <div
      id='testimonials'
      style={{ maxWidth: '100%' }}
    >
      <SectionHeading title={sectionTitle} titleUp={sectionTitleUp} center />
      <TestimonialCarouselStyle2 data={data?.length > 0 ? data : []} />
    </div>
  );
};

// Memoize the component to prevent unnecessary re-renders
export default memo(TestimonialSectionStyle3);
