"use client";

import { useState } from "react";
import { Icon } from "@iconify/react";
import React from "react";
import Modal from "react-modal";
import CustomImage from "../CustomImage/CustomImage";

export default function Portfolio({ index, imgAlt }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const imagesLinks = [
    "https://fanousclinicmain.blob.core.windows.net/website-images-icons/fanous-clinic-lobby-charlotte.webp?sp=r&st=2024-09-01T11:31:34Z&se=2030-01-01T20:31:34Z&spr=https&sv=2022-11-02&sr=b&sig=EEtN%2FLt4XzRASaIXFT7tV31ecPClvSxXDS2aZYYAdVE%3D",
    "https://fanousclinicmain.blob.core.windows.net/website-images-icons/fanous-clinic-laboratory-charlotte.webp?sp=r&st=2024-09-01T11:30:47Z&se=2030-01-01T20:30:47Z&spr=https&sv=2022-11-02&sr=b&sig=7borhwIeDcymktqW7WnCNKvUMRraQXPHd79o73uX6jA%3D",
    "https://fanousclinicmain.blob.core.windows.net/website-images-icons/fanous-clinic-front-entrance.webp?sp=r&st=2024-09-01T11:30:20Z&se=2030-01-01T20:30:20Z&spr=https&sv=2022-11-02&sr=b&sig=9G9%2BU0RCAwTzjVIY7CYUeVZ5M4CQUf22OhFKtAiHXb8%3D",
    "https://fanousclinicmain.blob.core.windows.net/website-images-icons/fanous-clinic-exam-room-charlotte.webp?sp=r&st=2024-09-01T11:29:44Z&se=2030-01-01T20:29:44Z&spr=https&sv=2022-11-02&sr=b&sig=0OAiwKGb%2BQ4EPxpeA6gONnWh8emlH4bK0HnGlnbUqGo%3D",
    "https://fanousclinicmain.blob.core.windows.net/website-images-icons/fanous-clinic-waiting-area.webp?sp=r&st=2024-09-01T11:34:01Z&se=2030-01-01T20:34:01Z&spr=https&sv=2022-11-02&sr=b&sig=%2BXeUt7EnPRX9JIqmRWzLvyU%2F2TmO0GbUpVnyKPaJSM4%3D",
  ];
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="cs-portfolio cs-style-1 cs-radius-20 overflow-hidden">
      <div
        className="cs-portfolio-img d-block cs-bg-filed st-lightbox-item"
        role="img"
        tabIndex={0}
        onClick={openModal}
        style={{ cursor: "pointer" }}>
        <CustomImage
          src={imagesLinks[index]}
          alt={imgAlt || "Gallery"}
          width={1600}
          height={1067}
          className="cs-radius-30 cs-why-fc-img"
        />
        <span className="cs-link-hover">
          <i>
            <Icon icon="fa6-solid:arrows-up-down-left-right" />
          </i>
        </span>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            background: "transparent",
            border: "none",
            overflow: "hidden",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            zIndex: 1600,
          },
        }}
        ariaHideApp={false}>
        <CustomImage
          src={imagesLinks[index]}
          alt={imgAlt || "Gallery"}
          width={1600}
          height={1067}
          onClick={closeModal}
          style={{ cursor: "pointer", borderRadius: "10px" }}
        />
      </Modal>
    </div>
  );
}
