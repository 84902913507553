'use client';

import React, { memo } from 'react';
import SectionHeading from '../../SectionHeading';
import Portfolio from '../../Portfolio';

const GallerySection = ({ sectionTitle, sectionTitleUp, data }) => {
  const parsedData = data.value ? JSON.parse(data.value) : [];

  return (
    <div className='container'>
      <div className='cs-gallery-grid-1'>
        <div className='cs-grid-item'>
          <SectionHeading title={sectionTitle} titleUp={sectionTitleUp} />
        </div>
        <div className='cs-grid-item'></div>
        {parsedData.map((item, index) => (
          <div className='cs-grid-item' key={index}>
            <Portfolio {...item} index={index}/>
          </div>
        ))}
      </div>
    </div>
  );
};

// Memoize the component to prevent unnecessary re-renders
export default memo(GallerySection);
