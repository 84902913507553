import { Icon } from '@iconify/react';
import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useLocale, useTranslations } from 'next-intl';
import { urlFor } from '../../../lib/sanity';

export default function Post({ blog }) {
  const currentLang = useLocale();
  const t = useTranslations('blogs');

  return (
    <div className={`cs-post cs-style-1`}>
      <Link
        href={`/${currentLang}/blogs/${blog.slug.current}`}
        className="cs-post-thumb cs-view-mouse">
        <Image
          src={urlFor(blog.mainImage).url()}
          alt={blog.imgAlt || blog.title}
          height={379}
          width={526}
          priority={false}
        />
      </Link>
      <div className="cs-post-info">
        <>
          {/*{blog.publishedAt ? (
            <div className='cs-post-meta'>
              {blog.publishedAt && (
                <div className='cs-posted-by'>{blog.publishedAt}</div>
              )}
            </div>
          ) : null}*/}
          <Link
            className="cs-post-title cs-heading-color cs-semibold cs-fs-32"
            href={`/${currentLang}/blogs/${blog.slug.current}`}>
            {blog.title}
          </Link>
        </>
          <Link
            href={`/${currentLang}/blogs/${blog.slug.current}`}
            className="cs-post-btn cs-heading-color cs-medium">
            {`${t("learnMoreButton")} ${blog.name}`}
          </Link>
      </div>
    </div>
  );
}
