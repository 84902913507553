import React from "react";
import Rating from "../Rating";
import { Box } from "@mui/material";

export default function TestimonialStyle3({ text, ratingNumber }) {
  return (
    <div className="p-2">
      <div className="cs-testimonial cs-style-3 cs-radius-20 cs-white-bg text-center testimonials-text">
        <Rating ratingNumber={ratingNumber} />
        <p
          className="cs-fs-20"
          style={{ color: "#222" }}>
          {text}
        </p>
      </div>
    </div>
  );
}
