"use client";

import React, { memo } from "react";
import SectionHeading from "../../SectionHeading";
import Team from "../../Team";
import { Box } from "@mui/material";

const TeamSection = ({ sectionTitle, sectionTitleUp, data }) => {
  const parsedData = data.value ? JSON.parse(data.value) : [];

  return (
    <div className="container">
      <SectionHeading
        title={sectionTitle}
        titleUp={sectionTitleUp}
        center
      />

      <Box
        className="row gy-3"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: { lg: "50px 0", md: "72px 0" },
        }}>
        {parsedData.map((item, index) => (
          <div
            className="col-lg-6"
            key={index}>
            <Team
              {...item}
              index={index}
            />
          </div>
        ))}
      </Box>
    </div>
  );
};

// Memoize the component to prevent unnecessary re-renders
export default memo(TeamSection);
