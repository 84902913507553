import { Icon } from "@iconify/react";
import React from "react";
import Link from "next/link";
import Image from "next/image";

export default function Team({
  index,
  imgAlt,
  name,
  designation,
}) {
  const imagesLinks = [
    "https://fanousclinicmain.blob.core.windows.net/website-images-icons/dr-hany-farag-primary-care-physician.webp?sp=r&st=2024-09-01T11:28:11Z&se=2030-01-01T20:28:11Z&spr=https&sv=2022-11-02&sr=b&sig=TzB8nhYdMPJklM%2BMAEc0Lq2MQrEgIooTSrynJaBVzm0%3D",
    "https://fanousclinicmain.blob.core.windows.net/website-images-icons/tory-grooms-clinical-pharmacist.webp?sp=r&st=2024-09-01T11:36:04Z&se=2030-01-01T20:36:04Z&spr=https&sv=2022-11-02&sr=b&sig=fL81MR%2FyTPaGUzrZn%2FpRbBWmsFBNlnzxzhQP5XunMvI%3D",
  ];
  return (
    <div
      className="cs-team cs-style-1 text-center cs-radius-20 cs-type-1"
      >
      <div className="cs-member-img">
        <Image
          src={imagesLinks[index]}
          alt={imgAlt || name}
          height={231}
          width={231}
          loading="lazy"
          priority={false}
        />
      </div>
      <div
        className="cs-team-meta"
        style={{
          position: "relative",
        }}>
        <h3 className="cs-member-name cs-semibold cs-fs-40">{name}</h3>
        <p className="cs-member-designation cs-fs-20 cs-heading-color">
          {designation}
        </p>
      </div>
    </div>
  );
}
