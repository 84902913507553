"use client";

import React, { useState } from "react";
import Popup from "../Popup/Popup";
import { useTranslations } from "next-intl";
import { Box } from "@mui/material";
import NewsLetterForm from "../forms/NewsletterForm/NewsLetterForm";
import CustomImage from "../CustomImage/CustomImage";
import Link from "next/link";


export default function IconBoxStyle4({
  title,
  subTitle,
  alt,
  index,
  notHilighted
}) {
  const [open, setOpen] = useState(false);
  const t = useTranslations("home");
  const iconsLinks = [
    "/images/icons/early-bird-services-icon.svg",
    "/images/icons/primary-care-services-icon.svg",
    "/images/icons/medication-optimization-services-icon.svg",
    "/images/icons/chronic-disease-management-icon.svg",
    "/images/icons/wellness-coaching-services-icon.svg",
  ]
  return (
    <>
      <Box
        className={`cs-iconbox cs-style-4 ${index === 0 && !notHilighted && "highlited"
          } services-card ${notHilighted &&"notHilighted"}`}

      >
        <div className="cs-iconbox-icon cs-accent-bg rounded-circle cs-center">
          <CustomImage notResponsive className="icon" src={iconsLinks[index]} alt={alt} height={19} width={19} />
        </div>
        <h2 className="cs-iconbox-title cs-fs-32">{title}</h2>
        <p className="cs-iconbox-subtitle">
          {subTitle}
        </p>

        {index === 0 ? (
          <>
            <Link href='tel:(980) 224-7288' id="early-bird-link">
              <button
                id="early-bird-btn"
                className="btn-download mb-0"
                onClick={() => {
                  setOpen(true);
                }}
              >
                {t("services.btnText")}
              </button>
            </Link>
          </>
        ) : null}
      </Box>
      { /* index === 4 ?

       <Popup open={open} setOpen={setOpen} sx={{ color: "#000", pb: 2, fontSize: "14px" }}>
          <p className="fw-bold cs-fs-48" >
            {t("services.popup.header")}
          </p>
          <p className="fw-bold cs-fs-24 opacity-75">
            {t("services.popup.subHeader")}
          </p>
          <p>
            {t("services.popup.body1")}{t("services.popup.body2")}
          </p>
  
          <>
            <NewsLetterForm formId="concierge-form" emailJsTemp={{ en: "fcweb-en-concierge-emp", es: "fcweb-es-concierge-emp" }} darkBg={false} type="concierge" />
          </>
        </Popup>

        : null */}
    </>
  );
}