import React, { useRef } from "react";
import { Box } from "@mui/material";
import TestimonialStyle3 from "../Testimonial/TestimonialStyle3";
import useMediaQuery from "@mui/material/useMediaQuery";
import Slider from "react-slick";
import { Icon } from "@iconify/react";

export default function TestimonialCarouselStyle2({ data }) {

  const isXLg = useMediaQuery("(min-width: 1600px)");
  const isMd = useMediaQuery("(min-width: 768px) and (max-width: 991px)");
  const isSm = useMediaQuery("(min-width: 575px) and (max-width: 768px)");
  const isXs = useMediaQuery("(max-width: 575px)");

  const slider1 = useRef(null);

  const slider1Settings = {
    slidesToShow: isXLg ? 3 : isXs || isSm ? 1 : 2,
    rows: 1,
    slidesPerRow: 1,
    arrows: false,
    infinite: true,
  };


  return (
    <>
      <Box
        sx={{
          width: {
            xs: "90vw",
            md: `90vw`,
          },
          margin: "0 auto",
          marginTop: "-4rem",
          position: "relative",
          overflow: "hidden",
          height: "100%",
        }}>
        <Slider
          ref={slider1}
          {...slider1Settings}>
          {data?.map((item, index) => (
            <div key={index}>
              <TestimonialStyle3 {...item} />
            </div>
          ))}
        </Slider>
      </Box>
      {data.length > (isMd ? 2 : isXs ? 1 : 2) ? (
        <div className="testimonial-one__slider-control cs-center ">
          <Icon
            icon="ep:arrow-left-bold"
            onClick={(e) => {
              e.preventDefault();
              slider1.current.slickPrev();
            }}
          />

          <Icon
            icon="ep:arrow-right-bold"
            onClick={(e) => {
              e.preventDefault();
              slider1.current.slickNext();
            }}
          />
        </div>
      ) : null}
    </>
  );
}
