"use client"

import React, { useState } from 'react';
import parse from 'html-react-parser';
import Link from 'next/link';
import { useLocale } from 'next-intl';
import {  ArrowRightAltRounded } from '@mui/icons-material';


export default function SectionHeading({
  title = '',
  titleUp = '',
  titleUpHref = '',
  titleDown = '',
  subTitle = '',
  center,
}) {
  const currentLang = useLocale()
  const [hover, setHover] = useState(false);

  return (
    <div
      className={`cs_section_heading cs_style_1 ${center ? 'text-center' : ''}`}
      style={{ marginTop: '2rem' }}
    >
      {titleUp && (
        <>
            <Link href={`/${currentLang}/${titleUpHref}`} className="cs-post-thumb cs-view-mouse">
              <h3 className="cs-section-subtitle text-uppercase cs-semibold m-0 cs-fs-32">
                {parse(titleUp)}
              </h3>
            </Link>
         
        </>
      )}

      {title && (
       
            <Link href={`/${currentLang}/${titleUpHref}`} className="cs-post-thumb cs-view-mouse">
                <h2 className="cs-section-title cs-fs-72" style={{ margin: 0,position: 'relative',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover .arrow': {
                    opacity: 1,
                    transform: 'translateX(0)',
                  }, }}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}>
                    {parse(title)}
                <ArrowRightAltRounded sx={{
                  position: 'absolute',
                  left: {lg:'80%',md:"95%",sm:"95%",xs:"90%"},
                  pt: 1,
                  fontSize: 50,
                  opacity: hover ? 1 : 0,
                  transform: hover ? 'translateX(0)' : 'translateX(-20px)',
                  transition: 'opacity 0.3s, transform 0.3s',
                }} />
                </h2>
            </Link>
      )}
      {titleDown && (
        <>
          <h3 className="cs-section-subtitle text-uppercase cs-accent-color cs-semibold m-0 cs-accent-color cs-fs-32">
            {parse(titleDown)}
          </h3>
        </>
      )}
      {subTitle && (
        <>
          <p className="m-0">{parse(subTitle)}</p>
        </>
      )}
    </div>
  );
}
