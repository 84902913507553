"use client";

import React, { memo } from "react";
import Post from "../../Post";
import SectionClickableHeading from "../../SectionClickableHeading";

const BlogSectionStyle2 = ({
  sectionTitle,
  sectionTitleUp,
  sectionTitleDown,
  sectionSubTitle,
  data,
}) => {
  return (
    <div className="container">
      <SectionClickableHeading
        title={sectionTitle}
        titleUp={sectionTitleUp}
        titleDown={sectionTitleDown}
        subTitle={sectionSubTitle}
        titleUpHref="blogs"
        center
      />
      <div
        className="row cs-row-gap-50"
        style={{ marginTop: "3rem" }}
        id="blogs-inHome-hero">
        {data?.map((item, index) => (
          <div
            className="col-xl-4 col-md-6"
            key={index}>
            <Post blog={item} />
          </div>
        ))}
      </div>
    </div>
  );
};

// Memoize the component to prevent unnecessary re-renders
export default memo(BlogSectionStyle2);
